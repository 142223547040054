import CookieConsent from "./src/components/cookie-consent/cookie-consent";
import React from "react";
import store from "./store";
import { Provider } from "react-redux";
import Layout from "./src/components/layout/layout";

let previousLocation;

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  let retVal = true;

  // No hash anchor link was clicked, assume page change and scroll to top fast
  // Prevent gatsby from scrolling
  if (!location.hash || location.hash.length === 0) {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => {
      window.scrollTo(0, 0);
      document.documentElement.style.scrollBehavior = "smooth";
    }, 0);
    retVal = false;
  }
  // Coming from a subpage to home scroll to the hash anchor link fast
  if (
    previousLocation?.pathname !== "/" &&
    location.pathname === "/" &&
    location?.hash?.length > 0
  ) {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => {
      document.documentElement.style.scrollBehavior = "smooth";
    }, 100);
  }
  // When the first navigation to the page includes an anchor link, scroll fast
  if (!previousLocation && location.hash.length > 0) {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => {
      document.documentElement.style.scrollBehavior = "smooth";
    }, 100);
  }
  previousLocation = location;
  return retVal;
};

export const wrapPageElement = ({ element }) => (
  <Provider store={store}>
    <Layout>
      {element}
      <CookieConsent />
    </Layout>
  </Provider>
);
